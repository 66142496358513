#calendarYear, #calendarDay{
    display: block;
    font-weight: 600;
}

.prettyLink{
    font-weight: 600; 
    cursor: pointer; 
    color: var(--clr-primary); 
    transition: all .3s; 
}

.bookingMessage.hidden{
    max-height: 0px; 
}

.bookingMessage{
    padding: 0px 10px;
    margin-bottom: var(--margin-bottom-var);
    text-align: center;
    max-height: 2rem;
    transition: all .3s;
}

.prettyLink:hover{
    color: var(--clr-fourth); 
}

#calendarYear{
   font-size: calc(var(--default-text-size)*.8); 
}

#calendarImg{
    cursor:pointer;
}

label {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
    -o-user-select: none;
    user-select: none;
}

.contactForm {
    padding: var(--default-padding);
    margin-top: var(--margin-bottom-var);
    overflow: hidden;
    color: var(--clr-black);
    border-style: solid;
    border-color: var(--clr-secondary);
    border-radius: var(--default-border-radius);
    border-width: 3px; 
    font-size: var(--default-text-size); 
}

.contactForm.green{
    border-color: var(--clr-third); 
}

.contactBody {
    margin: 20px var(--body-left-margin);
}

.contactBody.opaque{
    position: absolute;
    top: 0px;
    z-index: 20;
    left: 0px;
    background-color: rgba(0,0,0,.5411764705882353);
    margin: 0;
    padding: 20px var(--body-left-margin);
}

.contactForm.opaque .contactFormField label{
    margin: 0; 
}

.contactBody.opaque .contactForm{
    background-color: white; 
}

.exitBookingContainer{
    position: absolute;
    top: 0px;
    right: calc(var(--body-left-margin) + 2rem);
    top: calc(4rem);
    cursor: pointer;
    z-index: 1; 
}

.exitBookingContainer:hover > .exitBooking{
    transform: scale(1.05); 
    border-color: var(--clr-primary); 
}

.exitBookingContainer:hover > .exitBooking::before, 
.exitBookingContainer:hover > .exitBooking::after{
    background-color: var(--clr-primary); 
}

.exitBooking{
    position: relative;
    background-color: white;
    height: 4rem;
    width: 4rem;
    border-radius: 2rem;
    border-color: var(--clr-fourth);
    border-style: solid;
    border-width: 2px;
    /* text-align: center; */
    display: flex;
    margin: 0 auto;
}

.exitBookingText{
    background-color: white; 
    border-color: var(--clr-fourth); 
    font-size: calc(.8 * var(--default-text-size));
    text-align: center;  
    font-weight: bold; 
    margin-bottom: .5rem;
}

.exitBooking::before, .exitBooking::after{
    content: ''; 
    position: absolute; 
    height: 100%; 
    width: 5%; 
    background-color: var(--clr-fourth); 
    left: 47.5%;
}

.exitBooking::before{transform:rotate(45deg)}
.exitBooking::after{transform:rotate(-45deg)}

.contactFormContent{
    position:relative; 
}

.checkboxTours {
    float: left;
    margin-bottom: var(--margin-bottom-var);
    display: block;
    margin: var(--default-padding);
    font-weight: 600;
    width: 100%; 
    display: grid; 
    grid-template-columns: 22% 22% 22% 22%;
    grid-column-gap: 1%;
}


.checkboxContainer{
    position:relative; 
    cursor:pointer; 
    user-select: none; 
    display:block;
    margin: calc(var(--margin-bottom-var)/2) 0; 
    font-weight: 400; 
}

.checkboxContainer input{
    position: absolute; 
    opacity: 0;
    height: 0; 
    width: 0; 
}

.checkmark{
    position: absolute; 
    bottom: 5%;
    right: 3%;
    height: 50px;
    width: 50px;
    background-color: #eee; 
    border-radius: 5px;
}

.checkmark:after{
    content:"";
    position:absolute;
    display:none; 
}

.checkmark:after{
    left:17px;
    top:0px;
    width:12px;
    height: 40px;
    border:solid var(--clr-background); 
    border-width: 0 7px 7px 0;
    webkit-transform: rotate(45deg); 
    -ms-transform: rotate(45deg); 
    transform: rotate(45deg); 
}

.checkboxContainer:hover .checkmark{
    z-index:5; 
    background-color:hsl(0 0% 0% / 50%); 
    background-color: var(--clr-primary); 
}

.checkboxContainer input:checked ~ .checkmark,
.checked ~ .checkmark{
    background-color: var(--clr-primary); 
}

.checkboxContainer input:checked ~ .checkmark:after,
.checked ~ .checkmark:after{
    display:block; 
}

.bookingTransportationOption.blue{
    border-color: var(--clr-third); 
}

.bookingTransportationOption .checkboxContainer{
    padding-top: 1rem; 
    margin: 0; 
}

.checkboxContainer .cruiseContainer,
.checkboxContainer .islandCruiseContainer{
    width: 90%; 
}

.formGroup{
    display:block;
}

.calendarIcon {
    padding: calc(2*var(--default-padding));
    position: relative; 
    cursor: pointer;
    text-align: center;
    -webkit-user-select: none; 
    -moz-user-select: none;
    -ms-user-select: none; 
    user-select: none;
    transition: height .3s; 
    height: 30px; 
    display: inline-block; 
}

.calendarIconCalendar {
    position: absolute;
    left: 46%;
    top: 7px;
}

.calendar {

    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
    user-select: none;
    width: 65%;
    position: absolute;
    border-style: solid;
    border-color: var(--clr-primary);
    border-radius: var(--default-border-radius);
    border-width: 2px;
    background-color: var(--clr-background);
    z-index: 100;
    overflow: hidden;
    top: 5px; 
    left: 13%;
}

.calendarHeader{
    z-index: 10; 

    text-align:center;
    padding-top:var(--default-padding); 
    padding-bottom: var(--default-padding); 
    min-width: var(--calendar-width);
    position: relative;
}

.calendarHeader a{
    top: 20px;
    z-index: 200;
    padding: 0 var(--default-padding);
    display: inline-block;
    cursor: pointer;
    border-radius: 50%;
    border-style: solid;
    border-color: var(--clr-secondary);
}

.calendarHeader a:hover{
    
}

.bookingTransportationGridContainer{
    transition: all .3s; 
    max-height: 8rem; 
}

.bookingTransportationGridContainer.long{
    max-height: 15rem; 
}

.bookingTransportationGrid{
    display: grid; 
    grid-template-columns: auto auto;
    grid-column-gap: calc(2 * var(--default-padding)); 
    max-width: 92%
}

.bookingTransportationOption{
    border-style: solid;
    border-radius: var(--default-border-radius);
    border-color: var(--clr-secondary); 
    overflow: hidden;
    position: relative; 
    cursor: pointer; 
    transition: .3s all; 
    height: 7.5rem; 
    transition: all .3s ease-in-out; 
}

.bookingTransportationOption.red{
    transform: scale(.95); 
    color: black; 
    transition: all .3s ease-in-out; 

}

.bookingTransportationOption .checkmark{
    top: 5%; 
    z-index: 5; 
    right: 2%; 
}

.bookingTransportationOption:hover{
    transform: scale(1.02); 
}

.bookingTransportationTitle{
    position: absolute; 
    bottom: .4rem; 
    font-size: 1.2rem; 
    font-weight: 600; 
    z-index: 10; 
    left: 1rem; 
    user-select: none; 
}

.calendarHeader * {
    display: inline-block; 
}

.calendarBodyPadding{
    padding: 0px 20%; 
}

.prevMonth, .nextMonth{
    color: var(--clr-secondary);
    position: absolute; 
    top:-10px;
    transition: .3s ease; 
}

.prevMonth:hover, .nextMonth:hover{
    transform: scale(1.08); 
    border-color: #e61d4d;
    color: #e61d4d;
}

.prevMonth{
    left: 20%; 
}

.nextMonth{
    right: 20%; 
}

.day{
    padding: calc(var(--default-padding)*.5); 
    display:inline-block; 
    width: var(--day-size); 
    height: var(--day-size); 
    text-align: center;
    vertical-align: middle;
    position: relative; 
    font-size: calc(var(--default-text-size)*.8); 
    font-weight: 600;
    z-index:3;
}

.day.selected{
    background-color: var(--clr-primary);
    color: var(--clr-background); 
    border-radius: calc(var(--default-border-radius)/2); 
}

.day:not(.past){
    cursor:pointer; 
}

.past {
    background-color: rgba(116, 119, 156, .7 );
    color: var(--clr-secondary);
}

.day::after{
    content: ''; 
    position: absolute; 
    width: 90%;
    height: 75%;
    margin-left: auto; 
    margin-right: auto; 
    z-index: 1;
    top:0px;
    left:0px;
    border-color: var(--clr-primary); 
    border-style: solid; 
    opacity: 0; 
    border-width: 0px 0px 4px 0px; 
    transition: opacity .3s ease;
}

.day:not(.past, .selected):hover:after{
    opacity: 1;  

}

.contactFormField{
    display:inline-block; 
    margin: var(--default-padding); 
    position: relative; 
}

.contactFormField.text{
    width:29%; 
}

.contactFormField label{
    display: block; 
    margin-bottom: var(--default-padding); 
    font-weight: 600; 
    /* If you want to implement it in very old browser-versions */
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    /* The rule below is not implemented in browsers yet */
    -o-user-select: none;
    /* The rule below is implemented in most browsers by now */
    user-select: none;
}

.contactFormField input{
    border-style:solid;
    height: var(--contact-form-height); 
    border-radius: calc(var(--default-border-radius)/2);
    width: 100%; 
    border-width: var(--default-border-width); 
    font-size: calc(var(--default-text-size) * .8); 
    font-weight: 500; 
    padding: calc(var(--default-padding) * .5);
    padding-left: calc(var(--default-padding) * 1.5); 
    max-width: 90%; 
}

.formSelect{
    height: var(--contact-form-height);
    border-radius: calc(var(--default-border-radius)/2);
    width: 100%; 
    border-width: var(--default-border-width); 
    font-size: calc(var(--default-text-size)*.8); 
    padding-left: calc(var(--default-padding) * 1.5); 
    border-color: var(--clr-third); 
    max-width: 92.3%; 
    background-color: var(--clr-background); 
}

.finalField{
    display:block;
    width: 100%; 
}

.sizeOfParty, .numChildren{
    display: inline-block; 
    width: 48%; 
}

.messageArea {
    width: 90%;
    border-radius: calc(var(--default-border-radius)/2);
    border-width: var(--default-border-width);
    font-size: calc(var(--default-text-size)*.8);
    padding-left: calc(var(--default-padding) * 1.5);
}

.green {
    border-color: var(--clr-primary);
    border-color: var(--clr-third); 
}

.messageArea:focus, input, select{
    outline-width: 0px;
}

.messageArea.red, .formControl.red{
    border-color: var(--clr-secondary); 
}

.messageArea.red:focus, .formControl.red:focus{
    border-color: var(--clr-fourth); 
}

.messageArea.red{
    color: black; 
}

.maxWidth{
    width: 100%;
}

.submitButton{
    width: 95%; 
    font-size: var(--default-text-size); 
    font-weight: 600; 
    color: var(--clr-background); 
    background-color: var(--clr-primary); 
    border-radius: calc(var(--default-border-radius)/2); 
    border-width: var(--default-border-width); 
    border-style: solid; 
    text-align: center; 
    padding: calc(var(--default-padding)*.5); 
    margin: var(--default-padding); 
    cursor: pointer; 
}

.submitButton:hover{
    transform: scale(1.01); 
}

.calendarInput {
    border-style: solid;
    height: var(--contact-form-height);
    border-radius: calc(var(--default-border-radius)/2);
    width: 100%;
    border-width: var(--default-border-width);
    font-size: calc(var(--default-text-size) * .8);
    font-weight: 500;
    padding: calc(var(--default-padding) * .5);
    padding-left: calc(var(--default-padding) * 1.5);
    max-width: 90%;
}

.XIcon{
    top:10px;
    left:48%;
    height:100%;
    width: 50%;
    position: absolute; 
    cursor: pointer; 
}
.leftLine, .rightLine{
    top: 0px;
    left: 50px;
    width: 1%;
    height: 70%;
    background-color: black; 
}
.leftLine{
    transform: rotate(-45deg); 
}

.rightLine {
    transform: translate(0%, -100%) rotate(45deg);
}

.finalField input{
    width:100%;
}

.submitButton.red{
    background-color: var(--clr-secondary); 
    color: var(--clr-background); 
}

.red {
    color: var(--clr-secondary);
    opacity: 1;
    transition: opacity .5s ease;
}

.hidden{
    opacity: 0; 
    transition: opacity .5s ease; 
}

.greenCheck{
    content: ''; 
    margin-left: 0.2rem;
    top: -0.2rem;
    position: absolute;
    opacity: 1;
    height: 1rem;
    width: 0.3rem;
    transform: rotate(45deg);
    transition: opacity .5s ease-in-out;
    border-color: var(--clr-third);
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 3px;
    border-right-width: 3px;
}

.payPalButtonContainer{
    margin-top:var(--margin-bottom-var); 
}

.calendarExit{
    padding: 20px 0px;
    margin: 0px auto; 
    width: 100%;
    position:relative; 
}

.calendarIcon.space{
    height: 230px; 
}

.dualForm{
    display: grid; 
    grid-template-columns: 50% 50%; 
}

.incrBtn{
    margin: 0 .5rem; 
    padding: 0 .5rem; 
    font-size: calc(var(--default-text-size) * 1.2); 
    border-radius: 4rem; 
    border-style: solid; 
    border-color: var(--clr-primary); 
    border-width: 2px; 
    cursor: pointer;
    user-select: none; 
    transition: all .2s; 
    font-weight: 500; 
}

.incrBtn:hover{
    background-color: var(--clr-primary); 
    color: var(--clr-background); 
}


@media(min-width: 426px){
    .calendar{
        top: 60px;
    }

    .calendarIcon .XIcon{
        width:0px;
        height: 0px;
    }
}

@media(min-width: 1024px){
    .calendar{
        width: 50%; 
    }

    .calendarBodyPadding{
        padding: 0 2rem;
    }
}

@media(min-width: 1440px){
    .calendar{
        width: 40%; 
    }
}

@media(min-width: 1800px){
    .calendar{
        width: 30%; 
    }
}

@media(max-width: 1600px){
    .checkboxTours{
        grid-template-columns: 30% 30% 30%; 
        grid-column-gap: 1%; 
    }
}

@media(max-width: 1200px){

    .bookingTransportationTitle{
        font-size: .8rem; 
    }

    .checkboxTours{
        grid-template-columns: 44% 44%; 
        grid-column-gap: 3%; 
    }

    .exitBooking{
        height: 2rem;
        width: 2rem;
    }

    .exitBookingContainer{
        right: calc(var(--body-left-margin) + 1rem); 
        top: 2.5rem; 
    }
}

@media(max-width: 900px){
    .calendarBodyPadding{
        padding: 0 5%; 
        padding-bottom: 5%; 
    }
    
    .checkboxTours{
        grid-template-columns: 93%; 
    }
}

@media(max-width: 768px){
    .contactFormField.text{
        width: 27%; 
    }
}

@media(max-width: 425px){

    .contactFormField.text{
        width: 100%;
    }
    
    .exitBookingText{
        margin-bottom: .25rem; 
    }

    .exitBookingContainer{
        right: .25rem;
        top: .25rem; 
    }

    .checkmark{

        top:5px; 
        right:3%
    }

    .contactFormField label {
        margin-bottom: 5px; 
    }

    .bookingTransportationGrid{
        grid-template-columns: auto; 
        grid-row-gap: var(--default-padding); 
        width: 100%; 
        max-width: 100%; 
    }

    .checkboxContainer .cruiseContainer{
        top:0px; 
    }

    .bookingTransportationOption .checkboxContainer{
        padding: 0; 
    }

    .bookingTransportationTitle{
        font-size: var(--default-text-size);
        width: 100%;
        text-align: center;
        padding: 0px;
        margin: 0px;
        left: 0;
    }


    .bookingTransportationOption{
        height: 5.5rem; 
        width: auto;
    }

    .waterlines.small div{
        width: 168%; 
    }

    .bookingTransportationGridContainer{
        max-height:19rem; 
    }

    .bookingTransportationGridContainer.long{
        max-height:30rem;
    }

    .contactForm, .contactFormField input, .contactFormField select{
        font-size: calc(var(--default-text-size)*1.1); 
    }

    .contactFormField input { 
        padding-top: 0rem;
        padding-bottom: 0rem; 
    }

    .contactFormField{
        width:100%; 
        margin: calc(1*var(--default-padding)) 0px; 
    }

    p.imageCardBody{
        font-size: 1rem; 
    }

    .imageCardCaption{
        font-size: 1.3rem;
    }

    .imageCardBody{
        margin-bottom: .8rem;
    }

    .imageBodyGrid{
        grid-template-columns: 35% 20% 28%;
    }

    .checkboxTours{
        display: block;
        margin: 0; 
    }

    .checkmark{
        height: 35px; 
        width: 35px; 
    }

    .checkmark:after{
        left:12px;
        width:8px;
        height:25px; 
    }

    .formSelect{width:100%; max-width:97%;}

    .checkboxContainer{
        margin: var(--margin-bottom-var) 0px; 
    }

    .submitButton{
        width:95%; 
        margin-left:0px;
        margin-right:0px;
        padding: 10px 0px; 
    }

    .calendarIcon.space{
        height: 18rem;
    }

    .calendarHeader a{
        padding: var(--default-padding); 
        top: 0;
    }

    .calendar {
        top: 4rem; 
        width: 95%;
        left: 0px; 
    }

    .calendarBodyPadding{
        padding: 0px 5%;
        padding-bottom: 5%; 
    }

    .calendarHeader{
        min-width: auto; 
    }   
}

@media(max-width: 375px){
    .calendarBodyPadding{
        padding: 0px; 
    }
}