:root {
    --clr-background: #ffffff;
    --clr-primary: #2284c4;
    --clr-primary: #1963c5;
    --clr-secondary: #fc436f;
    --clr-third: #108527;
    --clr-fourth: #fec824;
    --clr-black: #000000;
    --margin-bottom-var: 20px;
    --body-left-margin: 180px;
    --numColumns: auto auto auto;
    --grid-gap: 3rem;
    --default-padding: 10px;
    --default-border-radius: 15px;
    --default-text-size: 1rem;
    --default-font-weight: 500;
    --default-line-height: 1.3rem;
    --default-border-width: 3px;
    --menuMargin: 130px;
    --imgWidth: 40px;
    --imgHeight: 20px;
    --day-size: 2rem;
    --calendar-width: 360px;
    --contact-form-height: 40px;
    --imageCardSpeed: 150ms;
    --imageContentSpeed: 400ms;
    --menuOptionSize: 12rem;
    --tabletWidth: 768px;
}

.navbarTripadvisorContainer {
    display: none;
    visibility: hidden;
    position: absolute;
    top: 1px;
    z-index: 2;
    right: var(--default-padding);
}

.faqTitle{
    margin-top: 3.5rem;
    text-align: center;
}

.slideShowLine{
    user-select: none; 
}

.pricingBox .imageCardBtn{
    text-align: center;
    padding: var(--default-padding) 10%;
    -webkit-user-select: none;
    user-select: none;
    width: 35%;
    justify-content: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.filterButton{
    height: 2rem;
    width: 2rem; 
}

.filterIcon{
    height: 2rem;
    width: 2rem; 
    overflow:hidden; 
    position: relative; 
    display:flex;
    margin-right: auto; 
    cursor: pointer; 
}

.filterIcon .filterCircle:nth-child(1){
    right: .25rem; 
    top:.25rem;
}

.filterIcon .filterCircle:nth-child(2){
    left: .25rem; 
    top: .8rem;
}

.filterIcon .filterCircle:nth-child(3){
    left: 1rem; 
    top: 1.35rem;
}

.filterIcon .filterLine:nth-child(4){
    top: .4rem; 
}

.filterIcon .filterLine:nth-child(5){
    top: .95rem;
}

.filterIcon .filterLine:nth-child(6){
    top: 1.5rem;
}

.filterContainer{
    position: relative; 
    top: -3rem; 
}

.filterBy{
    left: 2.2rem;
    top: 0.5rem;
    position: absolute;
    font-size: calc(.8 * var(--default-text-size));
    cursor: pointer;
    transition: all .3s ease-in-out; 
    user-select: none; 
}

.filterBy:hover, .filterContainer:hover > .filterBy{
    color: var(--clr-primary); 
}

.filterBy.hidden{
    visibility: hidden;
}

.filterCircle{
    border-width:1px; 
    height: .3rem; 
    width: .3rem; 
    border-style:solid; 
    border-radius: .3rem; 
    background-color: white; 
    border-color: var(--clr-primary); 
    position: absolute; 
    border-width:2px; 
    transition: all .3s; 
}

.filterOptionContainer{
    border-radius: var(--default-border-radius); 
    max-width: 0rem; 
    transition: all .3s; 
    position: absolute; 
    left: 2.2rem; 
    top: 0rem; 
    overflow:hidden;
}


.filterOption{
    position: relative; 
    font-size: calc(.7 * var(--default-text-size));
    background-color: var(--clr-primary); 
    color: white; 
    margin-bottom: -1px; 
    max-height: 0px; 
    transition: max-height .2s linear; 
    cursor: pointer; 
    display:flex; 
    align-items:center;
    justify-content: center; 
}

.filterOptionTitle{
    color: var(--clr-fourth); 
    font-weight: 600; 
    text-align: center; 
    max-height: 0px; 
    transition-delay: .3s;
    transition: all .3s; 
    border-style: solid; 
    border-top-left-radius: var(--default-border-radius);
    border-top-right-radius: var(--default-border-radius);
    border-bottom-width: 0px;
}

.filterOptionContainer.clicked{
    max-width: 12rem; 
    width: 12rem; 
    border-color:var(--clr-primary);
    z-index: 1; 
}

.filterOptionContainer.clicked > .filterOption{
    max-height: 2rem; 
    height: 2rem; 
    transition-delay: 300ms; 
}

.filterOption::before{
    content: ''; 
    position: absolute; 
    left: 0px;
    height: 100%; 
    width: 0px; 
    background-color: white; 
    transition: all .3s; 
}

.filterOption.chosen{
    font-weight: 600; 
    color: var(--clr-secondary); 
}


.filterOption:hover{
    font-weight: 600; 
}

.filterOption:hover::before{
    width: 3px; 
}

.filterOption.chosen::before{
    width:3px;
    background-color: var(--clr-secondary); 
}

.filterOptionContainer.clicked > .filterOptionTitle{
    padding: calc(.5 * var(--default-padding)); 
    max-height: 150px; 
}

/* 
.filterCircle::before, .filterCircle::after{
    content: ''; 
    position: absolute; 
    width: 100%; 
    height: .2rem; 
    background-color: var(--clr-primary); 
    width:2.5rem; 
    z-index: -1; 
}
*/ 

.filterLine{
    position: absolute; 
    width: 2rem;
    height: 3px; 
    background-color: var(--clr-primary); 
    z-index: -1; 
}

.filterCircle::before{
    left: -1.25rem; 
}*/ 

.filterIcon:hover > .filterCircle:nth-child(1){
    right: 1rem; 
}

.filterIcon:hover > .filterCircle:nth-child(2){
    transform:translateX(1rem); 
}

.filterIcon:hover > .filterCircle:nth-child(3){
    transform: translateX(-.75rem); 
}

.filterIcon.clicked .filterCircle:nth-child(1){
    transform: translateX(.25rem); 
}

.filterIcon.clicked .filterCircle:nth-child(2){
    transform: translateX(-.25rem); 
}

.filterIcon.clicked .filterCircle:nth-child(3){
    transform: translateX(.25rem); 
}

.filterIcon.clicked * {
    border-color: var(--clr-fourth); 
}
.filterIcon.clicked *::before, .filterIcon.clicked .filterLine{
    background-color: var(--clr-fourth);
}

.filterButton{
    display: none; 
}

.filterButton option{
    position: absolute; 
    width: 5rem; 
    height: 2rem; 
}

.filterButton.clicked{
    display: block; 
}

.tourBundleGrid{
    display:grid; 
    border-width: 2px;
    border-radius: var(--default-border-radius); 
    grid-template-columns: 78% 20%; 
    grid-column-gap: var(--default-padding); 
    overflow: hidden; 
}

.tourBundleGridSavings{
    font-size: 1rem; 
    color: var(--clr-primary); 
    font-weight: 600; 
}

.tourBundleGridLabel{
    text-align: center;
    padding: var(--default-padding) 0px; 
    display: flex;
    margin-left: auto;
    margin-right: auto; 
    font-weight: 600; 
    font-size: 1.2rem; 
}

.tourBundleGridCentered{
    text-align: center;
    font-size: 1.3rem;
    border-style: solid;
    border-color: var(--clr-secondary);
    border-radius: var(--default-border-radius);
    border-width: var(--default-border-width); 
    /* background-color: var(--clr-secondary); */
    width: calc(100% - 2*var(--default-border-width));
    height: 5rem;
    border-radius: var(--default-border-radius);
    margin-bottom: var(--default-padding);
    position: relative; 
    cursor: pointer; 
    display: grid;
    grid-template-columns: 80% 20%; 
    overflow: hidden; 
    transition: all .3s ease-in-out; 
}

.seeTour{
    opacity: 0; 
    color: white; 
    cursor: pointer;
    transition: all .3s ease-in-out; 
    position: absolute; 
    bottom: calc(3 * var(--default-padding)); 
    right: calc(3 * var(--default-padding)); 
    transition-delay: .4s; 
    font-weight: bold; 
    user-select: none;
}

.seeTour:hover{
    transition-delay: 0s; 
    transform: scale(1.05); 
}

.tourCardBundle:hover > .seeTour{
    opacity: 1; 
}

.tourBundleGridLink{
    cursor: pointer;
    font-size: 1.8rem;
    position: absolute;
    color: white;
    font-weight: 600;
    color: var(--clr-primary);
    height: 100%;
    width: 100%;
    top: 0%;
    left: 0%;
    padding-top: calc(2 * var(--default-padding));
    padding-left: calc(2* var(--default-padding)); 
    background: hsl(0 0% 0% / .2) 100%;
}

.tourBundleItems{
    height: calc(15rem + 3*var(--default-padding));
    overflow:hidden; 
    margin: 0; 
    padding: 0; 
}

.bundleLogoGrid{
    display:grid; 
    grid-row-gap: var(--default-padding); 
}

.bundleLogo{
    border-style:solid;
    border-radius: var(--default-border-radius); 
    border-color: var(--clr-fourth); 
    overflow: hidden; 
    height: 5rem; 
}

.bundleLogo.chosen{
    transform: scale(1.05);
    border-color: var(--clr-primary);
}

.bundleLogo.chosen > .bundleLogoItem{
    font-size: 1.1rem; 
}

.bundleLogo.label{
    border-color: var(--clr-secondary);
    font-size: 1.3rem;
    font-size: .9rem;
    font-weight: 600;
    /* text-align: center; */
    display: flex;
    margin-left: auto;
    margin-right: auto;
    /* padding: var(--default-padding); */
    padding: 0 var(--default-padding);
    /* top: var(--default-padding); */ 
}

.bundleLogo.empty{
    border-style: none; 
}

.tourCardBundle{
    width:100%; 
    height:100%; 
    position:relative; 
    border-radius: var(--default-border-radius); 
    background-size: cover;
    transition: all .5s ease-in-out; 
}


.tourCardBundle:hover{
    transform: scale(1.1); 
    max-height: 100%; 
    max-width: 100%; 
}

.tourCardBundle:hover > .tourBundleGridLink{
    color: #f7403c; 
}

.tourCardBundleItem{
    display: inline-block;
    height: 100%;
    width: 30%;
    position: relative; 
    overflow: hidden;
    border-radius: var(--default-border-radius); 
    /* margin: 0px 1%; */
    margin-right: calc(2*var(--default-padding));
}

.logoImg{
    position:absolute;
    top: 13px;
    left: 5px;
}

.foo{
    width:100%; 
}

*.lazy, * .lazy{
    background-image: none; 
    background-color: var(--clr-secondary); 
}

a{
    text-decoration:none; 
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h3 { 
    font-size: 2rem;
    font-weight: 700; 
    margin: 0px; 
    color: var(--clr-primary); 
}

h2 { 
    font-size: 1.6rem;
}

.homeTextBlock{
    color: var(--clr-black); 
    font-size: var(--default-text-size);
}

.flag{
    margin: 30px auto;
    position: absolute;
    width: 3.5rem;
    height: 1.5rem;
    /* top: -3.5rem; */
    /* right: 16rem; */
    transition: all .5s;
    right: 28%;
    bottom: 55%;
}

.cruiseContainer:hover > .flag{
    bottom: 70%; 
}

.hotelContainer{
    margin-top: 4rem; 
}

.hotelContainer.small{
    margin-top: 0rem; 
}

.flag::before, .flag::after{
    content: ''; 
    background-color: var(--clr-secondary); 
    position: absolute;
    height: 135%; 
    width: 5px; 
    top: 1px; 
    border-radius: 1rem;
}

.flag::before{
    left: 5px;
    height: 250%; 
}

.flag::after{
    right: 5px; 
}

.flagTop{
    position: relative; 
    width: 33%; 
    height:1.2rem; 
    display:flex;
    margin: 0 auto; 
    border-style:solid;
    border-color: var(--clr-secondary) transparent transparent transparent; 
    border-radius:1rem; 
    border-width: 5px;
}

.flagTop::before, .flagTop::after{
    border-style:solid;
    border-color: transparent transparent var(--clr-secondary) transparent; 
    border-radius:1rem; 
    position: absolute;
    content:''; 
    height:100%; 
    width:100%; 
    top:-135%; 
    border-width: 5px; 
}

.flagTop::before{
    left:-1.1rem;
}

.flagTop::after{
    right:-1.1rem; 
}

.logoGridItem {
    display: flex;
    flex-direction: row;
    gap: var(--default-padding);
    align-items: center;
}

.desktopNavbarText {
    font-weight: 700;
    font-size: 3rem;
    line-height: 56px;
    color: #fc436f;
    padding-left: 175px;
    padding-top: 15px;
}

.desktopNavbarLogoSpace{
    position:relative; 
}

.desktopLogo {
    width: 94px;
    height: 108px;
    position: absolute;
    top: -9px;
    left: 2px;
}

.desktopLogoBorder{
    width: 100px;
    height: 100px; 
    position:absolute; 
    background-color:var(--clr-background); 
    border-radius:50%; 
    left:1.8%; 
    top:9px; 
}

.desktopNavbarBottom {
    width: 100%;
    display: block;
    background-color: var(--clr-primary); 
}

.desktopMenuContainer {
    margin-left: var(--menuMargin); 
}

.desktopMenuOption, .desktopMenuLink {
    display: inline-block;
    padding: 10px 0px;
    width: var(--menuOptionSize);
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: #ffffff;
    cursor: pointer;
    font-size:1.1rem;
    position: relative; 
}

.desktopMenuOption a{
    color:#ffffff;
    text-decoration:none; 
}

.desktopNavbarStripe{
    position: absolute; 
    background-color: var(--clr-background); 
    height: 3px; 
    bottom:0px;
    width: 100%; 
    opacity: 0;
    transition: opacity .5s ease; 
}

.desktopNavbarTourStripe{
    height:100%; 
    width: 3px; 
    position: absolute; 
    background-color: var(--clr-background); 
    left:0px;
    opacity: 0; 
    transition: opacity .3s ease; 
}

.desktopMenuOption:hover .desktopNavbarStripe, .desktopMenuLink:hover .desktopNavbarStripe{
    opacity: 1;  
}

.desktopDropdown{
    width: var(--menuOptionSize); 
}


#desktopLand, #desktopCruise{
    z-index: 1;
    width: 100%;
    max-height: 0px; 
    display: block; 
    visibility: hidden; 
    position: absolute; 
    margin-top: 6px; 
    background-color: var(--clr-primary); 
    color: var(--clr-background); 
    text-align:left;   
    transition: max-height .5s ease-out, height .5s ease-out, visibility .2s ease-in-out; 
}


#desktopLand a, #desktopCruise a {
    position: relative; 
    opacity: 0; 
    transition: opacity .2s;
}

#desktopLand a:hover .desktopNavbarTourStripe, #desktopCruise a:hover .desktopNavbarTourStripe{
    opacity: 1; 
}

.desktopDropdown:hover #desktopLand, .desktopDropdown:hover #desktopCruise{
    max-height: 650px; 
    visibility: visible; 
    height: auto;
} 

.desktopDropdown:hover #desktopLand a, .desktopDropdown:hover #desktopCruise a{
    opacity: 1; 
}

.desktopDropdown div a {
    display: block;
    color: #ffffff;
}

.desktopDropdown div div a {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    padding-left:10px; 
}

.slideImage {
    display: block;
    overflow: hidden;
    object-fit: cover;
    margin-left: auto;
    margin-right: auto;
    -webkit-user-select: none;
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width:100%; 
}

.slideShowContainer{
    margin-bottom: var(--margin-bottom-var); 
    width:100%;
    position:relative; 
    background-color: black; 
    height:750px; 
    overflow:hidden; 
    background-repeat: no-repeat; 
    /* background: center center fixed; */ 
    background: center center; 
    background-size: cover; 
    background: none;
    background-image: none; 
    background-color: black; 
}

.homeContainer{

}

.homeContainer.toggled{
    max-height: 100vh; 
}

.homeContainer.toggled > *:not(.contactBody){
    user-select: none; 
    cursor: none; 
}

.slideShowText{
    position:absolute; 
    top: 10%; 
    left: var(--body-left-margin); 
    color: var(--clr-background); 
    font-size: 3rem; 
    font-weight: 700; 
    line-height: 4.3rem;
    z-index:2; 
}

.homeTitle {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 2.7rem;
    font-weight: 600;
    color: var(--clr-primary);
}

.homeTitle.centered{
    text-align:center; 
}

.tourBundleButtonContainer{
    padding: var(--default-padding); 
    overflow: hidden; 
}

.tourBundleButton{
    width: 100%; 
    padding: var(--default-padding) 0; 
    border-radius: var(--default-border-radius); 
    vertical-align: middle; 
    font-size: var(--default-text-size); 
    font-weight: bold; 
    cursor: pointer; 
    transition: all .3s ease-in-out; 
    color: var(--clr-primary); 
    border-style: solid;
    border-width: 2px; 
    border-color: var(--clr-primary); 
}

.tourBundleGridCentered:hover{
    border-color: var(--clr-primary); 
}

.tourBundleButton:hover, .tourBundleGridCentered:hover > .tourBundleButtonContainer .tourBundleButton{
    color: white; 
    background-color: var(--clr-primary); 
}

.homeBodyContainer{
    padding-left: var(--body-left-margin); 
    padding-right: var(--body-left-margin); 
}

.introText{
    font-weight:var(--default-font-weight); 
    font-size: var(--default-text-size); 
    line-height: var(--default-line-height); 
    margin: var(--margin-bottom-var) 0px;
}

.optionsGrid{
    margin: calc(2*var(--margin-bottom-var)) 0px;
    display: flex;
    gap: var(--grid-gap);
}

.gridOption {
    cursor: pointer;
    padding: calc(2 * var(--default-padding));
    border-style: solid; 
    border-color: var(--clr-secondary); 
    border-width: 3px;
    border-radius: var(--default-border-radius); 
    font-size: var(--default-text-size); 
    font-weight: var(--default-font-weight); 
    line-height: var(--default-line-height); 
    transition: all .3s; 
    max-height: 450px;
    user-select: none; 
}

.gridOption.full{
    max-height: 2000px; 
}

.OptionTitle{
    text-align:center; 
    margin-bottom: var(--margin-bottom-var); 
}

.OptionText {
    color: var(--clr-black); 
    visibility: visible;
    transition: all .3s ease-in-out 0s, z-index 1s linear 0.01s;
    max-height: 1000px; 
    transition-delay: .3s; 
}

.OptionText.short, .OptionText.short .homeTextBlock {
    visibility: hidden;
    transition: all .3s;
    max-height: 0px;
    transition-delay: .3s; 
    transition: visibility 0s; 
}

.tourTitle{
    text-align:center;
    margin: var(--margin-bottom-var) 0; 
}

.grid-container{
    max-width: 100%;
    overflow: hidden;
    margin: var(--margin-bottom-var) 0px;
    max-height: 1400px;
    transition: all 3s;
    display: flex;
    flex-wrap: wrap;
    gap: var(--grid-gap);
    justify-content: center;}

.grid-container.maxed{
    max-height: 6000px; 
        transition: all 3s; 
}

.imageCard {
    color: var(--clr-black);
    background-size: cover;
    padding: 10rem 0 0;
    padding: 12rem 0 0; 
    width: 40ch;
    width: 50ch;
    border-radius: var(--default-border-radius);
    overflow: hidden;
    transition: transform var(--imageCardSpeed) ease;
    max-height: 55%;
    height: 55%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.imageCardContent {
    padding: 1.5em;
    background: linear-gradient( hsl(0 0% 0% / 0), hsl(0 0% 0% / .3) 20%, hsl(0 0% 0% / 1) 100% );
    transform: translateY(130px); 
    transition: transform var(--imageContentSpeed) ease; 
}

.imageCardContent.smallCard {
    transform: translateY(0px); 
}

.imageCardContent.smallCard > .imageCardCaption{
    font-size: 1.2rrem; 
}

.imageCard.small{
    width: 25ch; 
    padding: 6rem 0 0; 
}

.imageCard.purchaseCard{ 
    width: 100%; 
    padding: 0;
    padding-bottom: 3.5rem;
    padding: 0; 
    height: 100%; 
    max-height: 100%; 
}

.imageCardContent > *:not(.imageCardCaption){
    opacity: 0;
    transition: opacity var(--imageContentSpeed) linear;
    transition-delay: 500ms; 
}

.imageCard.purchaseCard .imageCardContent{
    transform: none; 
    opacity: 1; 
    padding: .5em;
    background: linear-gradient( hsl(0 0% 0% / 0), hsl(0 0% 0% / .3) 20%, hsl(0 0% 0% / .7) 100% );
    height:100%; 
}

.imageCard.purchaseCard .imageCardContent > *{
    opacity: 1; 
}

.imageCardCaption{
    position: relative; 
    color: var(--clr-background); 
    width: max-content; 

}

.imageCardCaption::after{
    content: "";
    position: absolute; 
    height: 3px;
    background: var(--clr-primary); 
    width: calc(100% + 1.5rem); 
    bottom: -5px; 
    left: calc(1.5rem * -1); 
    transform: scaleX(0); 
    transform-origin: left;
    transition: transform var(--imageContentSpeed) ease; 
}

.imageCard.purchaseCard .imageCardCaption::after{
    content: ""; 
    height: 0px; 
}

.imageCard.purchaseCard .imageCardCaption{
    font-size: 1.4rem;
    font-weight: bolder;
    padding: 2px;
    background: hsl(0 0% 0% / .2) 100%;
}

.imageCardBody{
    text-indent: 0px;
    padding: 0px;
    margin: 0px;
    color: var(--clr-background); 
    margin-bottom: 1rem; 
}

.imageCardBtns {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 1.5rem;
}

.imageCardBtn {
    cursor: pointer;
    text-align:center;
    display: inline;
    text-decoration: none;
    color: var(--clr-background);
    background-color: var(--clr-primary);
    padding: .5em 1.25em;
    font-weight: 600;
    border-radius: .25rem;
    transition: all var(--imageCardSpeed) ease;
}

    .imageCardBtn:hover, .imageCardBtn:focus {
        background-color: var(--clr-secondary);
    }


.imageCard:hover .imageCardCaption::after {
    transform: scaleX(1);
}

.imageCard:hover .imageCardContent{
    transform: translateY(0); 
    transition-delay: var(--imageContentSpeed); 
}

.imageCard:hover .imageCardContent > * {
    opacity: 1;
}

.bottomBar {
    background-color: var(--clr-primary);
    margin-top: calc(4*var(--margin-bottom-var)); 
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    padding: 20px 40px;
    padding: calc(2*var(--default-padding)) calc(4*var(--default-padding)); 
}

.bottomBarImgContainer{
    display: inline-block; 
}

.bottomBarLogoText{
    display: inline-block;
    position: absolute;
    user-select: none; 
    font-size: 2.5rem;
    font-weight: 600;
    margin-top: 40px;
    color: var(--clr-background); 
}

.bottomBarLogoMenu{
    color: var(--clr-background); 
    font-weight:var(--default-font-weight); 
    font-size:calc(1.2 * var(--default-text-size)); 
}

.bottomBarLogoGrid{
    font-size: var(--default-text-size); 
    text-decoration: none; 
    display: grid;
    grid-template-columns: 40% 40%; 
    grid-row-gap: 20px; 
    margin-top: 20px; 
    grid-column-gap: 2rem; 
}

.bottomBarLogoGrid a{
    color: var(--clr-background); 
    transition: all .3s ease-in-out; 
}

.bottomBarLogoGrid a:hover{
    color: var(--clr-secondary); 
}
 
.bottomBarLogoTextBack{
    margin-left: -10px; 
}

.followUsTitle{
    display:inline-block;
}

.TripadvisorIcon{
    padding: 10px 0px;
}

.TripadvisorIcon:hover .owlPupil{
    transform: scale(2.5); 
}

.owlEye {
    border-style: solid;
    border-color: var(--clr-background);
    z-index: 3;
    border-radius: 50%;
    border-width: calc(var(--imgHeight)*.15);
    padding: calc(var(--imgWidth)*.1);
    background-color: var(--clr-primary); 
}

.owlTop {
    position: absolute;
    border-top: calc(var(--imgWidth)*.08) solid var(--clr-background);
    border-left: calc(var(--imgWidth)*.08) solid var(--clr-background);
    border-right: calc(var(--imgWidth)*0.08) solid var(--clr-background);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    background-color: var(--clr-primary);
    z-index: 3;
    height: 15%;
    width: 47%;
    height: 8px;
    left: 17%;
    top: -3px;
}

.owlTriangle {
    position: absolute;
    top: 1px;
    width: 0;
    height: 0;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    z-index: 2;
    border-top: 100px solid var(--clr-background);
    border-left: var(--imgHeight) solid transparent;
    border-right: var(--imgHeight) solid transparent;
    border-top: var(--imgHeight) solid var(--clr-background);
}

.owlHead {
    position: relative;
    background-color: var(--clr-primary);
    width: var(--imgWidth);
    height: var(--imgHeight);
    padding: 0px;
    margin: 0px;
    display: grid;
    grid-template-columns: auto auto;
    z-index: 1;
}

.owlPupil {
    background-color: var(--clr-background); 
    width: 100%;
    height: 100%;
    border-radius: 100%;
    z-index: 3;
}

.facebookIcon{
    background-color: var(--clr-primary); 
    width:var(--imgWidth); 
    height:40px; /*Used to be imageHeight+40px, caused error*/  
    position:relative;
    overflow:hidden; 
    border-style:solid;
    border-radius: 10px; 
    border-width: 4px; 
    border-color: var(--clr-background);
    display:inline-block;
    margin: 0px 10px;
    vertical-align:middle;
}

.facebookIcon *{
    transition: transform .2s ease-in-out; 
}

.facebookCurve {
    position: absolute;
    width: 12%;
    height: 80%;
    left: 45%;
    top: 20%;
    border-style: solid;
    border-width: 0px;
    border-top-width: 5px;
    border-left-width: 5px;
    border-color: var(--clr-background);
    border-top-left-radius: 15px;
}

.facebookLine{
    position:absolute; 
    width: 32%; 
    top: 50%; 
    border-style:solid; 
    height:1px;
    background-color: var(--clr-background);
    border-width:2px; 
    border-color: var(--clr-background); 
    border-radius: 5px;
    left: 33%;

}

.facebookTRRound{
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 5px;
    background-color: var(--clr-background);
    top: 22%;
    left: 62%;
}

.facebookIcon:hover .facebookLine,
.facebookIcon:hover .facebookCurve, 
.facebookIcon:hover .facebookTRRound{
    transform: translateX(11px); 
}

.instagramInnerCircle {
    position: absolute;
    width: 40%;
    height: 40%;
    top: 20%;
    left: 20%;
    border-radius: 50%;
    border-width: 4px;
    border-style: solid;
    border-color: var(--clr-background);
}

.facebookIcon:hover .instagramDot{
    transform: translateX(-12px) translateY(12px) scale(3.2); 
}

.instagramDot {
    position: absolute;
    width: 15%;
    height: 15%;
    border-radius: 50%;
    background-color: var(--clr-background);
    left: 75%;
    top: 10%;
}

.loadMoreToursBtn {
    width: 40%;
    padding: 10px 0px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: var(--clr-primary); 
    font-size: 1.4rem;
    font-weight: 600;
    color: #ffffff;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.loadMoreToursBtn:hover{
    background-color: var(--clr-secondary); 
}

.next{
    right: 2%;
    border-radius: 0px 5px 0px 0px;
    border-style: solid; 
    border-width: 6px 6px 0px 0px;
    transform: rotate(45deg);
}

.prev {
    left: 2%;
    border-radius: 0px 5px 0px 0px;
    border-style: solid;
    border-width: 6px 6px 0px 0px;
    transform: rotate(-135deg);
}

.prev, .next{ 
    cursor: pointer; 
    position: absolute; 
    top: 50%; 
    color: white; 
    font-weight: 600; 
    font-size :30px; 
    transition: .6s ease; 
    user-select: none; 
    padding: 15px;
    z-index: 2; 
}

.prev:hover, .next:hover{
    color: var(--clr-third); 
}

.mobileNavbar{
    display:none; 
}

.companyLogoMobile{
    text-align: left;
    float: left; 
}

.companyLogoContainer {
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
}

.navText{
    font-weight: 500; 
    font-size: 1.5rem; 
    text-align:center; 
    vertical-align: middle; 
    color: var(--clr-background); 
    padding: 30px 0px; 
}

.hamburger, .smallBurger {
    padding-left: 5px;
    /*padding-top: 20px;*/
    padding-bottom: 10px;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 10% 10% 10%;
    align-content: space-evenly;
    justify-content: normal;
    cursor: pointer;
    height: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.smallBurger {
    width: 100%;
    height: 100%;
    grid-row-gap: 20%;
    padding: 0px;
    grid-template-rows: 20% 20% 20%;
}

.mid{
    transition: opacity .3s, visibility .3s; 
}

.left, .right{
    transform: rotate(0deg); 
    transition: none; 
}


.mid, .left, .right{
    background-color: var(--clr-background); 
    border-radius: 10px; 
    font-size: 12px; 
}

.hamburger.toggled .mid, .smallBurger.toggled .mid{
    display:none; 
    opacity: 0;  
    transition: opacity .3s, visibility 0s; 
}

.hamburger.toggled .right, .smallBurger.toggled .right{
    transform: translateX(0%) rotate(-45deg); 
    transition: transform 200ms ease-in-out 25ms; 
}

.hamburger.toggled .left, .smallBurger.toggled .left{
    transform: translateY(260%) rotate(45deg); 
    transition: transform 200ms ease-in-out 25ms; 
}

.smallBurger.toggled .left{
    transform: translateY(210%) rotate(45deg); 
}

.dropdownContents{
    position: absolute; 
    left: 0px;
    margin: 0px;
    padding: 0px;
    transition: all .3s; 
    z-index: 100; 
    top: 92px; 
}

.dropdownOption {
    transition: all .3s ease-in-out 0s, visibility .3s ease-in-out 0s, z-index 1s linear 0.01s;
    visibility: hidden;
    max-height: 0px;
    display: block;
    color: var(--clr-background);
    background-color: var(--clr-primary);
    padding: var(--default-padding);
    font-size: calc(var(--default-text-size)*.7);
    font-weight: 500;
    display: grid;
    grid-template-columns: 80% 20%;
    min-width: 180px;
}

.dropdownOptionText{
    padding:5px 0px; 
}

.dropdownOption.show{
    padding-left: 10px;
    visibility: visible;
    transition: all .3s ease-in-out 0s, visibility .3s ease-in-out 0s, z-index 1s linear 0.01s;
    max-height: 40px;
}

.tourParagraph{
    visibility: hidden; 
    max-height: 0px; 
    transition: all .3s;
}

.tourParagraph.show{
    visibility: visible;
    transition: all .3s ease-in-out 0s, visibility .3s ease-in-out 0s, z-index 1s linear 0.01s;
    max-height: 1000px; 
}

.mobileSubmenuWrapper{
    height: 0px; 
}

.mobileSubmenuWrapper.show{
    height: auto; 
}

.hamburgerContainer {
    padding: 10px 5px;
}

#TA_certificateOfExcellence255{
    position: absolute; 
    height: 210px; 
    left: -10px; 
}

.deleteMe{
    position: absolute; 
}

.questionBox{ 
    padding: 1rem 0rem; 
}

.question{
    color: var(--clr-fourth); 
    font-weight: 500; 
    padding-bottom: .3rem;
    font-size: 1.25rem;
}

.answer{
    line-height: 1.25rem;
    font-size: 1.05rem; 
}

.prettyLink{
    text-decoration: underline; 
    color: var(--clr-primary); 
}

.safetyList{
    margin-block-start: 0em; 
    margin-block-end: 0em;
    padding-inline-start: 1em;
}

.imageBodyGrid{
    display: grid; 
    grid-template-columns: 40% 60%; 
    justify-content: space-evenly; 
}

.price{
    color: var(--clr-fourth); 
    font-weight: bolder;  
    /*background: hsl(0 0% 0% / .3) 100%; */ 
}

.grid-container-bundle{
    display: block; 
}

.bundleRow{
    display:grid; 
    grid-template-columns: auto auto auto auto; 
    margin: calc(4* var(--default-padding)) 0; 
}

.bundleLogoItem{
    display: block; 
    text-align: center; 
}

.showArrowContainer{
    position:relative;
    height: 2rem;
    width: 2rem; 
    border-style: solid; 
    border-width: 2px; 
    border-color: var(--clr-fourth); 
    border-radius: 1rem; 
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    vertical-align: middle;
}

.showContainer:hover > .showArrowContainer{
    background-color: var(--clr-fourth); 

}

.showContainer:hover > .showArrowContainer .showArrow{
    border-color: var(--clr-background); 
    transform: rotate(-45deg); 
}

.showMoreText{
    padding-right: var(--default-padding); 
    cursor:pointer; 
    font-size: calc(var(--default-text-size)*1.2);
    font-weight: 600; 
    user-select: none; 
}

.showArrow{
    position: absolute;
    left: 0.55rem;
    top: 0.45rem;
    height: 0.7rem;
    width: 0.7rem;
    border-style: solid;
    border-top-width: 0px;
    border-left-width: 0px;
    transform: rotate(45deg);
    border-color: var(--clr-fourth); 
    transition: all .3s; 
}

.showArrow.toggled{
    transform: rotate(-135deg); 
}

.cruiseWheel{
    position: absolute; 
    width: 4rem;
    height: 4rem; 
    top: 0.75rem;
    right: 0rem;
    z-index: 0; 
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

.islandCruiseWheel{
    top: 4.5rem;
    left: 1rem;
}

.cruiseWheelInner{
    position: relative;
    height: 100%;
    width: 100%;
    border-style: solid;
    border-radius: 50%;
    background-color:white; 
    border-color: var(--clr-secondary);
}

.cruiseWheelInner::after{
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    /* background-color: yellow; */
    top: 0%;
    left: 0%;
    background-color: white;
    /* border-color: var(--clr-secondary); */
    /* border-style: solid; */
    z-index: 3;
}

.cruiseWheelSunFace{
    position: relative;
    height: 70%;
    width: 70%;
    background-color: white;
    z-index: 4;
    border-radius: 50%;
    left: 10%;
    top: 10%;
    border-style: solid;
    border-color: var(--clr-secondary);
    overflow:hidden;
}

.sunFaceLeftCheek{
    position: absolute;
    background-color: var(--clr-secondary);
    border-color: var(--clr-secondary);
    width: 58%;
    border-top-right-radius: 46%;
    height: 75%;
    left: -12%;
    bottom: 0%;
}

.sunFaceLeftCheek::before{
    content: '';
    position: absolute;
    height: 24%;
    width: 41%;
    background-color: red;
    background-color: white;
    right: 31%;
    top: 9%;
    border-top-left-radius: 50%;
    /* border-top-right-radius: 50%; */
    border-bottom-left-radius: 70%;
    border-top-right-radius: 70%;
}

.sunFaceLeftCheek::after{
    border-style: solid;
    border-color: var(--clr-secondary);
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-top-right-radius: 46%;
    right: -4px;
    border-width: 4px;
    top: 0px;
}

.sunFaceRightCheek{
    position: absolute;
    background-color: var(--clr-secondary);
    border-color: var(--clr-secondary);
    width: 58%;
    border-top-left-radius: 46%;
    height: 75%;
    right: -12%;
    bottom: 0%;
}

.sunFaceRightCheek::after{
    border-style: solid;
    border-color: var(--clr-secondary);
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border-top-left-radius: 46%;
    left: -4px;
    border-width: 4px;
    top: 0px;
}

.sunFaceRightCheek::before{
    content: '';
    position: absolute;
    height: 24%;
    width: 41%;
    background-color: red;
    background-color: white;
    left: 31%;
    top: 9%;
    border-top-right-radius: 50%;
    /* border-top-right-radius: 50%; */
    border-bottom-right-radius: 70%;
    border-top-left-radius: 70%; 
}

.leftNostril{
    position: absolute;
    height: 39%;
    width: 20%;
    right: 0px;
    top: 17%;
    background-color: white;
    background-color: white;
    position: absolulte;
    border-bottom-left-radius: 80%;
}

.leftNostril::after{
    position: absolute;
    content: '';
    height: 45%;
    width: 120%;
    bottom: -13%;
    background-color: white;
    left: -43%;
    border-radius: 50%;
}

.rightNostril{
    position: absolute;
    height: 39%;
    width: 20%;
    left: 0px;
    top: 17%;
    background-color: white;
    background-color: white;
    position: absolulte;
    border-bottom-right-radius: 80%;
}

.rightNostril::after{
    position: absolute;
    content: '';
    height: 45%;
    width: 120%;
    bottom: -13%;
    background-color: white;
    right: -43%;
    border-radius: 50%;
}

.kylieJennerLips{
    position: absolute;
    background-color: white;
    width: 40%;
    height: 1%;
    z-index: 10;
    top: 0p;
    top: 0px;
    border-style: solid;
    border-width: 4px;
    top: 76%;
    left: 20%;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-width: 0px;
    border-color: white;
}

.kylieJennerLips::after{
    content: '';
    position: absolute;
    width: 52%;
    border-style: solid;
    border-color: white;
    background-color: white;
    bottom: -0.35rem;
    border-width: 1px;
    left: 19%;
    height: 0.1rem;
    border-radius: 0px 0px 50% 50%;
}

/*
.cruiseWheel::after{
    content: ""; 
    position: absolute; 
    height: 70%;
    width: 70%; 
    border-radius: 50%; 
    background-color: yellow; 
    top: 15% ;
    left: 15%; 
    z-index: 3; 
}*/ 

.cruiseWheelInner .bar:nth-child(2){
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg); 
}

.cruiseWheelInner .bar:nth-child(3){
    -moz-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg); 
}

.islandCruiseWheel .cruiseWheelInner .bar:nth-child(2){
    -moz-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg); 
}

.islandCruiseWheel .cruiseWheelInner .bar:nth-child(3){
    -moz-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg); 
}

.islandCruiseWheel .cruiseWheelInner .bar:nth-child(4){
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg); 
}

.islandCruiseWheel .cruiseWheelInner .bar:nth-child(5){
    -moz-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg); 
}

.islandCruiseWheel .cruiseWheelInner .bar:nth-child(6){
    -moz-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg); 
}

.islandCruiseWheel .cruiseWheelInner::after{
    top: -1%; 
}

.islandCruiseWheel .cruiseWheelInner .cruiseWheelSunFace{
    top: 10%; 
}

.cruiseWheelInner .bar{
    /* background-color: var(--clr-fourth); */
    height: 1.2rem;
    width: 123%;
    position: absolute;
    border-radius: 2px;
    /* background-color: green; */
    top: 0px;
    left: 0px;
    left: -16%;
    top: 30%;
    border-style: solid;
    z-index: 0;
    border-color: var(--clr-secondary);
    background-color:white; 
}

.islandCruiseWheel .cruiseWheelInner .bar{
    height: .5rem; 
    left: -17%;
    top: 37%
}

.islandCruiseWheel .cruiseWheelInner .bar::after{
    /* height: 3rem; */
    height: 0.25rem;
    width: 2rem;
    border-style: transparent;
    border-style: transparent;
    border-top-style: solid;
    border-style: solid;
    border-left-style: solid;
    /* transform: rotate( 
-45deg); */
    content: "";
    position: absolute;
    top: 3px;
    border-color: var(--clr-secondary);
    left: 5.4rem;
}

.islandCruiseWheel .cruiseWheelInner .bar::before{
        /* height: 3rem; */
        height: 0.25rem;
        width: 2rem;
        border-style: transparent;
        border-style: transparent;
        border-top-style: solid;
        border-style: solid;
        border-left-style: solid;
        /* transform: rotate( 
    -45deg); */
        content: "";
        position: absolute;
        top: -1px;
        border-color: var(--clr-secondary);
        right: 5.4rem;
}

.cruiseContainer{
    position:relative; 
    width:70%;
    left: 15%;  
    margin-top: 4rem; 
}

.cruiseContainer.hotelContainer{
    width: 100%; 
    left: 0; 
}

.cruiseContainer.smallCruiseContainer{
    padding: var(--default-padding);
    max-width: 15rem; 
    margin: 0; 
}

.cruiseContainer:hover > .cruiseWheel{
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;}


/* 
.islandCruiseContainer:hover > .cruiseWheelSunFace{
    -webkit-animation: none; 
}*/ 

.cruiseUpperDeck, .cruiseMiddleDeck, .cruiseLowerDeck, .mayanUpperDeck{
    background-color: white; 
    z-index: 1; 
    width: 35%; 
    height: 1.5rem; 
    display: flex;
    margin-left: auto; 
    margin-right: auto; 
    position: relative; 
    border-top-color: var(--clr-primary); 
    border-style: solid;
    border-width: 2px 0px 0px 0px;
    border-radius: .5rem;
}

.mayanUpperDeck.small{
    margin-top: .5rem; 
    height: .75rem; 
}

.cruiseUpperDeckSmall, .cruiseMiddleDeckSmall, .cruiseLowerDeckSmall,
.cruiseUpperDeck.small, .cruiseMiddleDeck.small, .cruiseLowerDeck.small{
    height: .75rem; 
}

.cruiseMiddleDeck.small::before, .cruiseMiddleDeck.small::after{
    height: .75rem; 
}

.cruiseMiddleDeck.small::before{
    left: 0px;
}

.cruiseMiddleDeck.small::after{
    right: 0px; 
}

.cruiseMiddleDeck{
    width: 50%; 
}

.islandCruiseContainer{
    overflow:hidden;
    padding-top:5rem;
}

.islandUpperDeck{
    margin-bottom:1rem; 
}

.island{
    position: absolute;
    width: 40%;
    height: 2rem;
    border-style: solid;
    border-color: var(--clr-secondary);
    /* border-width: 0px 2px 2px 2px; */
    border-radius: 1rem;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 30rem;
    height: 40rem;
    top: 5.75rem;
    border-radius: 50%;
    border-width: 2px;
    left: calc(50% - 15rem);
    background-color: white; 
}

.island.small{
    top: 1.5rem;
    width: 15rem;
    height: 15rem;
    left: calc(50% - 7.5rem);}

.waterLines{
    width:100%; 
    display:grid; 
    grid-template-columns: auto auto auto auto auto auto auto auto auto auto; 
    grid-column-gap:0px; 
    margin-top:-4.25rem;
    position: relative; 
}

.waterLines.small{
    margin-top: -5rem; 
}

.waterLines div{
    border-style: solid;
    border-color: var(--clr-primary);
    border-width: 2px;
    height: 5rem;
    width: 158%;
    border-radius: 50%;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    z-index: 2; 
    position: relative; 
}

.waterLines::after{
    content: '';
    position: absolute;
    bottom: -1.7rem;
    width: 110%;
    height: 2rem;
    background-color: white;
}

.mayanUpperDeck{
    width:20%;
}

.mayanBottomBar{
    width: 51%;
    border-color: var(--clr-primary);
    border-style: solid;
    border-width: 1px;
    display: flex;
    margin-right: auto;
    margin-left: auto
}

.hotelPort{
    position: absolute; 
    left: 40%;
    bottom: 0%; 
    height: 70%; 
    border-radius: 30% 30% 0% 0%; 
    width: 20%; 
    background-color: var(--clr-primary); 
}

.cruiseLowerDeck{
    width: 85%;
    margin-right:10%; 
}

.cruiseLowerDeck::before{
    content: '';
    position: absolute; 
    background-color: var(--clr-primary);
    width: 2px; 
    height: 2.5rem; 
    transform: rotate(-40deg); 
    left: .75rem; 
    top: -3px; 
}

.cruiseLowerDeckSmall.cruiseLowerDeck::before
{
    height: 1.65rem;
    top: -2px;
    left: 0.55rem;
    z-index: 1;
}

.cruiseLowerDeckSmall.cruiseLowerDeck::after{
    height: 1.25rem; 
    width: calc(100% - .97rem);
}

.cruiseLowerDeck::after{
    content: ''; 
    position: absolute;
    width: calc(100% - 1.55rem);
    height: 2rem; 
    right: 0px;
    top: -2px;
    border-style: solid; 
    border-radius: .5rem;  
    border-width: 2px; 
    border-left-width: 0px; 
    border-color: var(--clr-primary) var(--clr-primary) var(--clr-primary) transparent; 
    border-top-left-radius: 0px; 
    background-color: white; 
}

.cruiseUpperDeck::before, .cruiseMiddleDeck::before, .mayanUpperDeck::before{
    content: ''; 
    position: absolute; 
    /*left: -1rem; 
    height: 0px;
    width: 0px; 
    border-top-width: 1rem;
    border-left-width: .5rem;
    border-bottom-width:1rem;
    border-right-width:.5rem; 
    border-style: solid; 
    border-color: transparent blue blue transparent; */ 
    background-color: var(--clr-primary); 
    width: 2px; 
    height: 1.5rem; 
    transform: rotate(15deg); 
    left: -3px; 
    top: 1px; 
}

.cruiseUpperDeck::after, .cruiseMiddleDeck::after, .mayanUpperDeck::after{
    content: ''; 
    position: absolute; 
    /*right: -1rem; 
    height: 0px;
    width: 0px; 
    border-top-width: 1rem;
    border-left-width: .5rem;
    border-bottom-width:1rem;
    border-right-width:.5rem; 
    border-style: solid; 
    border-color: transparent transparent blue blue; */ 
    background-color: var(--clr-primary); 
    width: 2px; 
    height: 1.5rem; 
    transform: rotate(-15deg); 
    right: -3px; 
    top: 1px; 
}

/*BEGIN MEDIA*/ 
@media(max-width:1800px)
{
}

@media(min-width: 1024px){

    .slideImage{
        min-height: 100%; 
    }

    .showMoreText{
        font-size: var(--default-text-size); 
    }
}

@media(min-width: 1440px){

}

@media(max-width:1300px){
    :root{
        --menuOptionSize: 7.5rem;
        --grid-gap: 2rem;
        --numColumns: auto auto; 
        --body-left-margin: 80px; 
    }

    .desktopMenuOption, .desktopMenuLink{
        font-size: 1rem; 
    }

    .bottomBarLogoText{
        left: 48%; 
        font-size: 1.6rem; 
    }
}

@media(max-width: 1024px)
{
    :root {
        --margin-bottom-var: 15px;
        --numColumns: auto;
        --grid-gap: 2rem;
        --default-padding: 10px;
        --default-border-radius: 15px;
        --default-text-size: .9rem;
        --default-font-weight: 500;
        --default-line-height: 1.1rem;
        --menuMargin: 160px;
        --body-left-margin: 110px;
        --menuOptionSize: 6.8em;
    }

    .bottomBarLogoText{
        display: none; 
    }

    .imageCard{
        width: 100%;
    }

    .desktopMenuOption, .desktopMenuLink{
        font-size: .9rem;
    }

    .desktopDropdown div div a{
        font-size: .9rem
    }

    .grid-container{
        grid-template-columns: var(--numColumns); 
    }
}

@media(max-width: 768px){

    :root{
        --numColumns: auto; 
        --body-left-margin: 60px; 
    }

    .tourBundleItems{
        height: 10rem;
        margin-bottom: var(--default-padding); 
    }

    .tourBundleGridCentered{
        border: none; 
        height: auto; 
        grid-template-columns: 65% 35%;
        font-size: var(--default-text-size); 
        font-weight: 500; 
        user-select: none; 
    }

    .tourBundleGridLink{
        font-size: var(--default-text-size); 
        width: 80%; 
        color: white; 
        padding-right: calc(2*var(--default-padding)); 
    }

    .tourBundleButtonContainer{
        padding: 0;
    }

    .tourBundleButton{
        width: auto; 
    }

    .bundleLogoGrid{
        position: relative; 
        display: block; 
    }


    .bundleLogo{
        display: inline-block; 
        width: 30%; 
        margin: var(--default-padding) 0; 
        margin-right: var(--default-padding); 
        margin-bottom: 0px; 
        height: 5.5rem; 
        position: relative; 
    }

    .bundleLogoItem{
        position: absolute; 
        bottom: .2rem; 
        width: 100%; 
        z-index: 1; 
    }

    .bundleLogo .cruiseContainer{
        position: absolute; 
        top: 0px; 
        padding-left: 0px; 
    }

    .bundleLogoItem.smallCruiseContainer{
        bottom: auto; 
    }

    .bundleLogo.label{
        margin: 0; 
        height: auto; 
        display: block; 
        width: auto; 
        border: none; 
        padding: 0px; 
        font-weight: 500; 
        border-radius: 0; 
    }

    .bundleLogo{
        margin-right: calc(var(--default-padding) - 2*var(--default-border-width)); 
    }

    .bundleLogoTextContent{
        display: flex;
        margin: 0 auto; 
        font-size: var(--default-text-size); 
    }

    .tourBundleGrid{
        grid-template-columns: auto; 
        padding: var(--default-padding); 
        border-style:solid;
        border-color: var(--clr-primary); 
        border-width: var(--default-border-width);
        border-radius: var(--default-border-radius); 
        position: relative; 
    }

    .mobileNavbar{
        display: grid;
        grid-template-columns: 12% auto auto;
        background-color: var(--clr-primary); 
        min-height: 4.4rem; 
    }

    .desktopNavbar{
        display:none; 
    }

    .imageCard{
        width:100%; 
        max-height: 100%;
    }

    .bottomBar{
        grid-template-columns: auto; 
        grid-row-gap: var(--grid-gap); 
        padding: calc(2*var(--default-padding));
        max-width:100%; 
        overflow:hidden; 
    }

    .bottomBarImgContainer img{
        height: 50%;
        width: 50%; 
    }

    .bottomBarLogoText{
        font-size: 1.4rem; 
        margin-top: 20px; 
        left: 35%; 
    }

    .imageCardContent{
        transform: none; 
        transition: none; 
        padding-bottom: 2.5rem; 
    }

    .imageCardContent > *:not(.imageCardCaption){
        opacity: 1;
        transition: none;
        transition-delay: 0s; 
    }

    .slideShowContainer {
        height: 450px; 
        background: black center center no-repeat; 
    }

}

@media(max-width: 425px){

    :root{
        --grid-gap: 20px; 
        --body-left-margin:20px; 
        --default-text-size: .8rem;
        --default-line-height: 1rem; 
        --day-size: 1.6rem; 
    }


    .faqFilter .filterContainer{
        top: 0; 
    }

    .cruiseLowerDeckSmall, 
    .cruiseLowerDeckSmall.cruiseLowerDeck::after{
        height: .8rem; 
    }

    .cruiseLowerDeckSmall.cruiseLowerDeck::before{
        height: 1rem; 
    }

    .bundleLogo .cruiseContainer{
        width: 110%; 
        left: -5%; 
    }

    .tourBundleGrid{
        margin: var(--margin-bottom-var) 0; 
    }

    .tourBundleGridCentered{
        grid-template-columns: 65% 35%; 
        font-size: var(--default-text-size); 
    }

    .islandUpperDeck{
        top: .5rem;
    }

    .island.small{
        top: 2rem;
        width: 15rem;
        height: 15rem;
        left: calc(50% - 7.5rem);
        width: 10%;
        width: 8rem;
        height: 8rem;
        left: calc(50% - 4.5rem);
    }

    .waterLines.small div{
        width: 180%; 
    }

    .tourBundleItems{
        height: 7rem;
    }

    .tourCardBundleItem{
        margin-right: var(--default-padding); 
    }

    .homeTitle{
        font-size: 1.5rem;
    }

    .slideShowText{
        display:none; 
    }

    .slideShowContainer{
        height: 300px; 
    }

    .dropdownOption{
        font-size: calc(.8*var(--default-text-size)); 
    }

    h3{
        font-size: 1.4rem; 
    }

    h2{
        font-size: 1.2rem; 
    }

    .loadMoreToursBtn{
        width: 100%; 
        font-size: 1.2rem; 
        border-radius: var(--default-border-radius); 
    }

    .navText{
        padding: 15px 0px; 
    }


    .companyLogoContainer img{
    /* top: 10px; */
    /* position: absolute; */
    /* top: 5%; */
    padding: 10%;
    padding-left: 5%;
    }

    .dropdownContents{
        top:68px; 
    }

   .dropdownOption.show{
       padding: calc(.8*var(--default-padding)); 
   }

   .slideShowContainer{
        background-repeat: no-repeat;
        /* background: center center fixed; */
        background: black center center;
        background-size: cover;
   }

   .gridOption{
       padding: var(--default-padding); 
   }

}
    